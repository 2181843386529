import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';

interface Props {
  openDialogue: boolean;
  setOpenDialogue: React.Dispatch<React.SetStateAction<boolean>>;
}

const HowToPopover: FC<Props> = ({ openDialogue, setOpenDialogue }) => {
  const { t } = useTranslation();

  const firstPage = (
    <>
      <div className="p">
        <p className="p block mb-2 text-center">{t('directions')}</p>
        <div className="">
          <p className="p text-center  text-primary h3 mb-12 relative">
            <span className="text-muted-foreground/60 fancy absolute top-[90%] right-[17%] text-base">
              {/* 1. {t('steps.words')} &#8598; */}
            </span>
            Vivid, Rocket
          </p>
          <p className="p text-center text-foreground border rounded-lg p-1 mb-2 relative">
            <span className="text-muted-foreground/60 fancy absolute -top-[23%] text-base">
              {/* 2. {t('steps.story')}&darr; */}
            </span>
            The
            <span className="text-primary">&nbsp;rocket&nbsp;</span>
            flew through the sky, becoming a
            <span className="text-primary">&nbsp;vivid&nbsp;</span>
            experience that I would never forget!
          </p>
          <Button
            className="opacity-90 w-full "
            onClick={() => setOpenDialogue(false)}
          >
            <span className="text-muted-foreground/70 fancy -left-[-1%]  absolute small text-base">
              {/* 3. {t('steps.done')}&rarr; */}
            </span>
            {t('play')}
          </Button>
        </div>

        {/* <img className="h-[330px]" src={HowToPlayGif} /> */}
      </div>
    </>
  );

  // const examplePage = (
  //   <>
  //     <h3 className="h3 text-center italic mb-6">Featuring Spanish mode!</h3>
  //     <div className="flex justify-center">
  //       <img className="h-[330px]" src={SpanishMode} />
  //     </div>
  //   </>
  // );

  // const featurePage = (
  //   <>
  //     <h3 className="h3 text-center italic mb-6">Featuring dark mode!</h3>
  //     <div className="flex justify-center">
  //       <img className="h-[330px]" src={DarkMode} />
  //     </div>
  //   </>
  // );

  const lastPage = (
    <>
      <h3 className="h3 italic mb-0">FAQ</h3>
      <h3 className="font-bold blockquote text-center mt-0 pt-0">
        {t('faq.question1')}
      </h3>
      <p className="small mt-2 italic text-center">{t('faq.answer1')}</p>
    </>
  );

  return (
    <Dialog open={openDialogue} onOpenChange={setOpenDialogue}>
      <DialogContent className="w-[98%] mx-auto block">
        <DialogTitle className="text-center h3 p-0 m-0">
          {t('welcome.title')}&nbsp;
          <span className="text-primary h3 p-0 m-0">Stwory</span>
        </DialogTitle>
        <DialogDescription>
          <p className="text-center mb-4">{t('welcome.desc')}</p>
        </DialogDescription>
        {/* <Carousel className="relative w-full"> */}
        {/* <CarouselContent> */}
        <div className="h-[330px] overflow-x-auto overflow-y-scroll relative ">
          {[firstPage, lastPage].map((page) => (
            <div className="mb-2">{page}</div>
          ))}
        </div>
        {/* </CarouselContent> */}
        {/* <CarouselPrevious className="absolute left-20 top-[93%]" /> */}
        {/* <CarouselNext className="absolute right-20 top-[93%]" /> */}
        {/* </Carousel> */}
      </DialogContent>
    </Dialog>
  );
};
export default HowToPopover;

