import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';

export const useRandomQuote = () => {
  const fetchCall = async () => {
    const response = await fetch('https://api.quotable.io/random');
    const msg = await response.json();

    return msg;
  };

  // we are using react query. This is just a basic call with a refetch function passed through
  const { data, isLoading, error, refetch } = useQuery('quote', fetchCall);

  return { data, isLoading, error, refetch };
};

export const useSupabase = (callback: any, ...params: any[]) => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>({});

  const refetch = (...params: any) => fetchData(...params);
  const fetchData = async (...refetch: any) => {
    // @ts-ignore
    const { data, error } = await callback(...(refetch || params));

    if (error) {
      setError(true);
    } else {
      setData(data);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(...params);
    setIsLoading(true);
  }, []);

  // @ts-ignore
  return { data, error, isLoading, refetch };
};

