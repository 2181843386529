import Nav from '@/components/shared/TopNav/TopNav';
import {
  ChallengeType,
  LeaderboardType,
  StworyBoardEntry,
  StworyBoardInsertChannel,
  fetchAllDates,
  getALlStworyBoardEntries,
  getFormattedDate,
  getMyEntry,
  getStworyDailyTable,
  likePost,
  supabase,
  unlikePost,
} from '@/lib/db/supabase';
import { useSupabase } from '@/lib/hooks';
import { useEffect, useState } from 'react';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useUser } from '@/lib/hooks/useUser';
import { toast } from '@/components/ui/use-toast';
import { Badge } from '@/components/ui/badge';
import { Heart } from 'lucide-react';
import Drawing from '../Game/Drawing';
import i18next from 'i18next';
import { BeatLoader } from 'react-spinners';
import { LoginViaInstagram } from '@/components/shared/LoginViaInstagram/LoginViaInstagram';
import { SelectWrapper } from './SelectWrapper';
import { useLanguage } from '@/lib/hooks/language';

interface Like {
  id: string;
  likes: number;
  is_liked: boolean;
}

const sendDateForQuery = (days: string, today: string) => {
  return days === today ? getFormattedDate() : days;
};
export default function Leaderboard() {
  const { t } = useLanguage();
  // @ts-ignore
  const [leaderboardType, setLeaderboardType] = useState<LeaderboardType>(
    LeaderboardType.RealTime
  );

  const { user } = useUser();

  const includeMe = window.location.search.length > 1;

  const [days, setDaysSelected] = useState<string>(t('days.today'));

  const {
    data: leaderboardEntries,
    isLoading,
    error,
    refetch,
  } = useSupabase(
    getALlStworyBoardEntries,
    i18next.language,
    leaderboardType,
    user?.id,
    sendDateForQuery(days, t('days.today'))
  );

  const {
    data: dailyWords,
    // @ts-ignore
    isLoading: dailyWordsLoading,
    // @ts-ignore
    error: dailyWordsEror,
    // @ts-ignore
    refetch: refetchDailyWords,
  } = useSupabase(getStworyDailyTable, i18next.language);

  const {
    data: previousDailyWords,
    // @ts-ignore
    isLoading: previousDailyWordsLoading,
    // @ts-ignore
    error: previousDailyWordsEror,
    // @ts-ignore
    refetch: refetchPreviousDailyWords,
  } = useSupabase(fetchAllDates, i18next.language);

  const [newLeaderboardEntries, setNewLeaderboardEntries] = useState<
    StworyBoardEntry[]
  >([]);

  useEffect(() => {
    const id = window.location.search.slice(1, window.location.search.length);
    if (id) {
      setLeaderboardType(LeaderboardType.Me);
    }
  }, []);

  useEffect(() => {
    if (
      !(days === t('days.today')) &&
      leaderboardType === LeaderboardType.RealTime
    ) {
      setLeaderboardType(LeaderboardType.World);
    }
  }, [days]);

  useEffect(() => {
    const id = window.location.search.slice(1, window.location.search.length);
    refetch(
      i18next.language,
      leaderboardType,
      user?.id,
      id ? id : '2394283',
      sendDateForQuery(days, t('days.today'))
    );
    setNewLeaderboardEntries([]);
    if (i18next.language) {
      refetchDailyWords(i18next.language);
    }

    if (leaderboardType === LeaderboardType.RealTime) {
      supabase
        .channel(StworyBoardInsertChannel)
        .on(
          'postgres_changes',
          { event: 'INSERT', schema: 'public', table: 'stwory_board' },
          (payload) => {
            const getEntry = async () => {
              const newRow: StworyBoardEntry = payload.new as StworyBoardEntry;

              const entry = await getMyEntry(newRow.id!, user?.id);

              const correctInfo = entry.data[0]!;

              setNewLeaderboardEntries((prev: StworyBoardEntry[]) => {
                return [correctInfo, ...prev];
              });
            };

            getEntry();

            // @ts-ignore
          }
        )
        .subscribe();
    }

    // @ts-ignore
  }, [i18next.language, leaderboardType, days]);

  const getPreviousWords = () => {
    const previousDate = previousDailyWords.find(
      // @ts-ignore
      (day: string) => day.date === days
    );

    return {
      firstWord: previousDate.firstword,
      secondWord: previousDate.secondwrod,
    };
  };

  const todaysWords =
    days === t('days.today')
      ? {
          firstWord: dailyWords[0]?.firstWord,
          secondWord: dailyWords[0]?.secondWord,
        }
      : getPreviousWords();

  const checkValidity = (entries1: StworyBoardEntry[]) => {
    if (
      !entries1 ||
      (typeof entries1 === 'object' && !Array.isArray(entries1))
    ) {
      return false;
    }

    return true;
  };
  const getLeadboard = (
    entries1: StworyBoardEntry[],
    entries2: StworyBoardEntry[]
  ) => {
    if (leaderboardType === LeaderboardType.RealTime) {
      return [...entries2, ...entries1];
    }
    return entries1;
  };

  const entries = checkValidity(leaderboardEntries)
    ? getLeadboard(leaderboardEntries, newLeaderboardEntries)
    : [];

  const [likes, setLikes] = useState<Like[]>([]);

  useEffect(() => {
    if (!entries || entries.length === 0 || !entries?.length) {
      return;
    }

    const sbLikes = entries.map((stworyBoardEntry: StworyBoardEntry) => {
      return {
        id: stworyBoardEntry.id,
        likes: stworyBoardEntry?.likes || 0,
        is_liked: stworyBoardEntry?.is_liked,
      };
    });

    // @ts-ignore
    setLikes(sbLikes);
  }, [leaderboardEntries, newLeaderboardEntries]);

  const likePostWrapper = (
    user_id: number,
    stwory_board_id: string,
    is_liked: boolean
  ) => {
    if (is_liked) {
      unlikePost({ user_id: user_id, stwory_board_id: stwory_board_id });
    } else {
      likePost({
        user_id: user_id,
        stwory_board_id: stwory_board_id,
      });
    }

    const likeCount = is_liked ? -1 : 1;

    setLikes((prev: Like[]) => {
      return prev.map((like: Like) => {
        if (like.id === stwory_board_id) {
          return {
            likes: like.likes + likeCount,
            id: like.id,
            is_liked: !is_liked,
          };
        }
        return like;
      });
    });
  };

  const handleClick = (e: any, is_liked: boolean) => {
    if (!user?.id) {
      toast({
        title: '😓',
        description: t('mustBeLoggedIn'),
        variant: 'default',
        duration: 2000,
        action: <LoginViaInstagram />,
      });

      return;
    }

    const id = e.target.closest('figure').dataset.key;

    likePostWrapper(user.id, id, is_liked);
  };

  const getRows = () => {
    return entries?.length === 0 ? (
      <div className="justify-center items-center flex text-primary">
        <p className="py-8">{t('noResults')}</p>
      </div>
    ) : (
      entries
        ?.filter(
          (entry: StworyBoardEntry) => entry.language === i18next.language
        )
        .map((stworyBoard: StworyBoardEntry, index: number) => {
          const placesAward = () => {
            if (index === 0) {
              return '🥇';
            } else if (index === 1) {
              return '🥈';
            } else if (index === 2) {
              return '🥉';
            } else {
              return '';
            }
          };

          const placesJSX = () => {
            if (index > 2) {
              return <></>;
            }
            return <span className="">{placesAward()}</span>;
          };

          function isJsonString(str: string): boolean {
            try {
              JSON.parse(str);
            } catch (e) {
              return false;
            }
            return true;
          }

          // @ts-ignore
          const isInstagramUser = stworyBoard.isinstagramuser ? (
            <a
              className="pl-2 p w-full text-blue-500 "
              href={`https://instagram.com/${stworyBoard.username}`}
            >
              {stworyBoard.username}
            </a>
          ) : (
            <p className="pl-2 p w-full ">
              {stworyBoard.username}
              {placesJSX()}
            </p>
          );

          const topPart = isInstagramUser;

          const stworyContent = !isJsonString(stworyBoard.stwory)
            ? stworyBoard.stwory
            : JSON.parse(JSON.parse(stworyBoard.stwory));

          const stripPunctuation = (word: string) => {
            return word
              .replaceAll('!', '')
              .replaceAll('.', '')
              .replaceAll(',', '')
              .replaceAll('?', '')
              .toLowerCase();
          };

          const showStworyWords = (content: string) => {
            return content.split(' ').map((word: string) => {
              const strippedWord = stripPunctuation(word);
              if (
                todaysWords?.firstWord?.toLowerCase() === strippedWord ||
                todaysWords?.secondWord?.toLowerCase() === strippedWord
              ) {
                return <span className="text-primary">{word} </span>;
              }
              return word + ' ';
            });
          };

          const stwory =
            stworyBoard.challenge === ChallengeType.Story ? (
              <div className="flex justify-center items-center h-full">
                <span className="text-center">
                  {showStworyWords(stworyContent)}
                </span>
              </div>
            ) : (
              <>
                {
                  <div className="">
                    <Drawing
                      // @ts-ignore
                      display={true}
                      lines={stworyContent}
                    />
                  </div>
                }
              </>
            );

          const likesButton = (
            <>
              <Badge
                onClick={(e) => {
                  handleClick(e, likes[index]?.is_liked);
                }}
                variant={'outline'}
                className="cursor-pointer hover:opacity-[100%] absolute right-3 bottom-5 px-4 large opacity-[50%] gap-2 flex "
              >
                {likes[index]?.likes}
                <Heart
                  fill={likes[index]?.is_liked ? '#f00' : '#500'}
                  color={likes[index]?.is_liked ? '#f00' : '#500'}
                />
              </Badge>
            </>
          );

          return (
            <figure
              className="relative h-[60vh] border border-border rounded-2 w-full overflow-y-hidden  hover:none select-none cursor-pointer"
              key={stworyBoard.id}
              data-key={stworyBoard.id}
              onDoubleClick={(e) => {
                handleClick(e, likes[index]?.is_liked);
              }}
            >
              <span className="absolute top-0 left-0">{topPart}</span>
              {stwory}
              {likesButton}
            </figure>
          );
        })
    );
  };

  const tableRows =
    error || isLoading ? (
      <p>Error, please try refreshing the page</p>
    ) : (
      getRows()
    );

  const allDates = previousDailyWords.length
    ? ['Today', ...previousDailyWords.map((day: any) => day.date)]
    : [];

  return (
    <main className="sm:px-6 lg:px-8 pt-24 min-h-screen max-w-[600px] mx-auto">
      <Nav title={t('leaderboard')} backArrow="/main" />
      <div className="relative flex justify-between sm:block">
        <h3 className="sm:text-center text-primary h3">
          {dailyWordsLoading ? (
            <>
              <BeatLoader color="#fff" />
            </>
          ) : (
            <>
              {todaysWords?.firstWord} {todaysWords?.secondWord}
            </>
          )}
        </h3>
        <div className="text-center small text-foreground sm:absolute sm:right-3 sm:bottom-0">
          <SelectWrapper
            allDates={allDates}
            value={days}
            onChange={setDaysSelected}
          />
        </div>
      </div>
      <Tabs
        className="py-0 overflow-hidden"
        value={leaderboardType}
        onValueChange={(value) => {
          // @ts-ignore
          setLeaderboardType(value);
        }}
      >
        <TabsList className="py-0 flex h-[50px]">
          {Object.values(LeaderboardType)
            .filter((value) => {
              if (
                days !== t('days.today') &&
                value === LeaderboardType.RealTime
              ) {
                return false;
              }

              if (includeMe) {
                return true;
              }

              return value !== LeaderboardType.Me;
            })
            .map((value) => (
              <TabsTrigger
                className="h-[100%] translate-y-[4px] shadow-none px-10 py-4"
                key={value}
                value={value}
              >
                {t(`leaderboardType.${value}`)}
              </TabsTrigger>
            ))}
        </TabsList>
      </Tabs>
      {isLoading ? (
        <div className="flex justify-center mt-8">
          <BeatLoader color="#fff" />
        </div>
      ) : (
        <div className="overflow-y-hidden">{tableRows}</div>
      )}
      <div></div>
    </main>
  );
}

