import { Github, Instagram } from 'lucide-react';
import { FC, useState } from 'react';
import SerranoLabsLogo from '../../../../public/logo_black.jpg';

interface Props {
  githubHandle: string;
  instagramHandle: string;
  discordHandle: string;
}

const MadeWithLove: FC<Props> = (props): JSX.Element => {
  const [madeWithLove, _] = useState(Math.trunc(Math.random() * 10 + 1));

  const getHeartType = () => {
    if (madeWithLove === 1) {
      return '❤️';
    } else if (madeWithLove === 2) {
      return '🧡';
    } else if (madeWithLove === 3) {
      return '💛';
    } else if (madeWithLove === 4) {
      return '💚';
    } else if (madeWithLove === 5) {
      return '💙';
    } else if (madeWithLove === 6) {
      return '💜';
    } else if (madeWithLove === 7) {
      return '🖤';
    } else if (madeWithLove === 8) {
      return '🤍';
    } else if (madeWithLove === 9) {
      return '🤎';
    }
    return '💕';
  };

  return (
    <div className="flex justify-center gap-4">
      <p className="text-foreground">Made with love {getHeartType()}</p>{' '}
      <span className="flex gap-2">
        <a
          target="_blank"
          className="text-foreground"
          href={`https://www.instagram.com/${props.instagramHandle}`}
        >
          <Instagram />
        </a>
        <a
          target="_blank"
          className="text-foreground"
          href={`https://github.com/${props.githubHandle}`}
        >
          <Github />
        </a>
        <a
          target="_blank"
          className="text-foreground"
          href={`${props.discordHandle}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="#000000"
            viewBox="0 0 256 256"
          >
            <path d="M104,140a12,12,0,1,1-12-12A12,12,0,0,1,104,140Zm60-12a12,12,0,1,0,12,12A12,12,0,0,0,164,128Zm74.45,64.9-67,29.71a16.17,16.17,0,0,1-21.71-9.1l-8.11-22q-6.72.45-13.63.46t-13.63-.46l-8.11,22a16.18,16.18,0,0,1-21.71,9.1l-67-29.71a15.93,15.93,0,0,1-9.06-18.51L38,58A16.07,16.07,0,0,1,51,46.14l36.06-5.93a16.22,16.22,0,0,1,18.26,11.88l3.26,12.84Q118.11,64,128,64t19.4.93l3.26-12.84a16.21,16.21,0,0,1,18.26-11.88L205,46.14A16.07,16.07,0,0,1,218,58l29.53,116.38A15.93,15.93,0,0,1,238.45,192.9ZM232,178.28,202.47,62s0,0-.08,0L166.33,56a.17.17,0,0,0-.17,0l-2.83,11.14c5,.94,10,2.06,14.83,3.42A8,8,0,0,1,176,86.31a8.09,8.09,0,0,1-2.16-.3A172.25,172.25,0,0,0,128,80a172.25,172.25,0,0,0-45.84,6,8,8,0,1,1-4.32-15.4c4.82-1.36,9.78-2.48,14.82-3.42L89.83,56s0,0-.12,0h0L53.61,61.93a.17.17,0,0,0-.09,0L24,178.33,91,208a.23.23,0,0,0,.22,0L98,189.72a173.2,173.2,0,0,1-20.14-4.32A8,8,0,0,1,82.16,170,171.85,171.85,0,0,0,128,176a171.85,171.85,0,0,0,45.84-6,8,8,0,0,1,4.32,15.41A173.2,173.2,0,0,1,158,189.72L164.75,208a.22.22,0,0,0,.21,0Z"></path>
          </svg>
        </a>

        <a target="_blank" href="https://serranolabs.io">
          <img src={SerranoLabsLogo} width={24} height={24} />
        </a>
      </span>
    </div>
  );
};

export default MadeWithLove;

