import Nav from '@/components/shared/TopNav/TopNav';

const Terms = () => {
  return (
    <main className="lg:max-w-[900px] lg:mx-auto mt-[10rem]">
      <Nav backArrow="/" />
      <h2 className="h2">Terms of Service</h2>
      <blockquote className="blockquote mb-4">
        Effective Date: September 1st, 2024
      </blockquote>
      <p className="p">
        Welcome to Serrano Labs LLC. By accessing or using our website,
        products, or services, you agree to comply with and be bound by these
        Terms of Service.
      </p>
      <ul className="*:text-lg list-disc py-4">
        <li>You must be at least 13 years old.</li>
        <li>
          No inappropriate content. Seek instagram's guidelines for what's
          innapropriate.
        </li>
        <li>
          We are not liable for any indirect, incidental, or consequential
          damages arising from your use of our services.
        </li>
      </ul>
      <p>
        If you have any questions, please reach out on{' '}
        <a className="text-sky-400" href="https://discord.gg/GFyGTcmjVD">
          Discord
        </a>
      </p>
    </main>
  );
};

export default Terms;

