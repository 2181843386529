import Nav from '@/components/shared/TopNav/TopNav';

const PrivacyPolicy = () => {
  return (
    <main className="lg:max-w-[900px] lg:mx-auto mt-[10rem]">
      <Nav backArrow="/" />
      <h2 className="h2">Privacy Policy</h2>
      <blockquote className="blockquote mb-4">
        Effective Date: September 1st, 2024
      </blockquote>
      <p className="p">
        At Serrano Labs LLC, We are committed to protecting your privacy and
        ensuring that your personal information is handled in a safe and
        responsible manner. This policy explains how we collect, use, and
        protect your personal information when you use our website.
      </p>
      <p className="p">
        We use Posthog in order to analyze user experiences on our website so
        that we can ensure the best user flow. We don't collect any information
        regarding the user. Other than that, we only store your username.
      </p>
    </main>
  );
};

export default PrivacyPolicy;

