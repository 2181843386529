import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { useUser, userFlow } from '@/lib/hooks/useUser';
import i18next from 'i18next';
import { Languages, Settings } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginViaInstagram } from '../LoginViaInstagram/LoginViaInstagram';

interface Props {}

import { Moon, Sun } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useTheme } from '@/components/ui/ThemeProvider';
import { BeatLoader } from 'react-spinners';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

export function ModeToggle() {
  const { setTheme } = useTheme();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="w-full text-primary-background">
          <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => setTheme('light')}>
          Light
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme('dark')}>
          Dark
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme('system')}>
          System
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

const ProfileCard: FC<Props> = () => {
  const { t } = useTranslation();
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const { user, isLoading } = useUser();

  useEffect(() => {
    if (!openPopover) {
      setShowLanguageMenu(false);
      setShowAccountMenu(false);
    }
  }, [openPopover]);

  const imageButton = (
    <div className="rounded-full h-16 w-16 relative p-0">
      <Button
        className="large h-full w-full rounded-full bg-transparent text-primary-foreground"
        variant={'ghost'}
      >
        <Settings
          className="text-primary stroke-foreground/90"
          width={30}
          height={30}
        />
      </Button>
    </div>
  );

  const logoutButton = (
    <>
      <li>
        <Button variant={'ghost'} onClick={userFlow.logout} className="w-full">
          {t('Logout')}
        </Button>
      </li>
      <li>
        <Dialog>
          <DialogTrigger asChild>
            <Button
              variant={'ghost'}
              // onClick={userFlow.purgeData}
              className="w-full"
            >
              {t('purgeData')}
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogTitle className="h3">{t('purgeData')}</DialogTitle>
            <DialogDescription>
              <p>{t('purgeDataDesc')}</p>
            </DialogDescription>
            <Button
              variant={'destructive'}
              onClick={userFlow.purgeData}
              className="w-full"
            >
              {t('purgeData')}
            </Button>
          </DialogContent>
        </Dialog>
      </li>
      <li>
        <Dialog>
          <DialogTrigger asChild>
            <Button variant={'ghost'} className="w-full">
              {t('deleteAccount')}
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogTitle className="h3">{t('deleteAccount')}</DialogTitle>
            <DialogDescription>
              <p>{t('deleteAccountDesc')}</p>
            </DialogDescription>
            <Button
              variant={'destructive'}
              onClick={userFlow.purgeUser}
              className="w-full"
            >
              {t('deleteAccount')}
            </Button>
          </DialogContent>
        </Dialog>
      </li>
    </>
  );

  const settingsMenu = (
    <>
      <li>
        <Button
          className="p-0 flex gap-4 text-foreground w-full"
          variant={'ghost'}
          onClick={() => setShowLanguageMenu(true)}
        >
          <Languages />
          {t('language')}
        </Button>
      </li>
      <li>
        <ModeToggle />
      </li>
      <li>
        <a href="/privacy">
          <Button variant={'ghost'} className="w-full">
            Privacy Policy
          </Button>
        </a>
      </li>
      <li>
        <a href="/terms">
          <Button variant={'ghost'} className="w-full">
            Terms
          </Button>
        </a>
      </li>
      {user?.id ? (
        <li>
          <Button
            variant={'ghost'}
            className="w-full"
            onClick={() => setShowAccountMenu(true)}
          >
            Account
          </Button>
        </li>
      ) : (
        <></>
      )}
    </>
  );

  const accountMenu = user?.id ? logoutButton : <></>;

  const languageMenu = (
    <>
      <li>
        <Button
          className="px-2 flex gap-4"
          variant={'ghost'}
          onClick={() => {
            setOpenPopover(false);
            setShowLanguageMenu(false);
            i18next.changeLanguage('en');
            localStorage.setItem('lang', 'en');
          }}
        >
          <span>🇺🇸</span>English
        </Button>
      </li>
      <li>
        <Button
          className="px-2 flex gap-4"
          variant={'ghost'}
          onClick={() => {
            setOpenPopover(false);
            setShowLanguageMenu(false);
            i18next.changeLanguage('es');
            localStorage.setItem('lang', 'es');
          }}
        >
          <span>🇪🇸</span>
          Español
        </Button>
      </li>
    </>
  );

  const LoginButton = (
    <div className="flex items-center">
      <LoginViaInstagram />
    </div>
  );

  const getMenu = () => {
    if (showLanguageMenu) {
      return languageMenu;
    } else if (showAccountMenu) {
      return accountMenu;
    }

    return settingsMenu;
  };

  const imageContent = (
    <>
      <Popover open={openPopover}>
        <PopoverTrigger onClick={() => setOpenPopover(!openPopover)} asChild>
          {imageButton}
        </PopoverTrigger>
        <PopoverContent className="w-42 p-0">
          <ul className="px-2 py-2 divide-y">{getMenu()}</ul>
        </PopoverContent>
      </Popover>
    </>
  );

  const other = (
    <>
      <div className="flex flex-col justify-center text-primary">
        <p>{user?.username}</p>
        <div className="flex gap-2 justify-between">
          {/* <p>🌟</p>
          <p>389</p> */}
        </div>
      </div>
    </>
  );

  const content = user?.username ? (
    <>
      {imageContent}
      {other}
    </>
  ) : (
    <>
      {imageContent}
      {LoginButton}
    </>
  );

  return (
    <figure className="flex justify-center align-center absolute top-3 right-3 sm:gap-2">
      {isLoading ? (
        <p>
          <BeatLoader color="#fff" />
        </p>
      ) : (
        content
      )}
    </figure>
  );
};
export default ProfileCard;

