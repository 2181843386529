import { createClient } from '@supabase/supabase-js';

// Don't forget types.
export const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL!,
  import.meta.env.VITE_SUPABASE_KEY!
);

export const StworyDailyTable = "stwory_daily";


export enum ChallengeType {
  Drawing = "drawing",
  Story = "story"
}
export interface StworyDaily {
  id: string;
  language: string;
  firstWord: string;
  secondWord: string;
  isComplete: boolean;
  challenge: ChallengeType;
  createdAt: string;
}

export function getFormattedDate() {
  const today = new Date();
  
  // Get year, month, and day
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const dd = String(today.getDate()).padStart(2, '0');

  return `${yyyy}/${mm}/${dd}`;
}



export const getStworyDailyTable = async (language: string) => {
  if (language !== "en" && language !== "es") {return {data: [], error: null}}


  const today = getFormattedDate();

  return supabase
    .from(StworyDailyTable)
    .select(`*`)
    .eq('isComplete', 'false')
    .eq('language', language)
    .eq('date', today);
}



  export const StworyBoardTable = "stwory_board";

  export interface StworyBoardEntry {
    id?: string;
    stwory?: any;
    reactions?: string;
    stwory_id?: string;
    user_id?: number;
    language?: string;
    username?: string;
    challenge?: ChallengeType;
    likes?: number;
    is_liked?: boolean;
    room_id?: string;
  }

export const fetchAllDates = async (language: string) => { 
  if (language === "") return 

      return await supabase.rpc('queryalldates').eq('language', language);
}

  export const StworyBoardInsertChannel = 'stwory-board-insert-channel'
  export const listenToStworyBoardTable = async () => {
supabase.channel(StworyBoardInsertChannel)
.on(
  'postgres_changes',
  { event: 'INSERT', schema: 'public', table: 'stwory_board' },
  (payload) => {
    console.log('Change received!', payload)
  }
)
.subscribe()
  }

export const insertStworyBoardEntry = async (stworyBoardEntry: StworyBoardEntry, userFlow: boolean = false)  => {
    if (userFlow) {
      let data;
      let error;

      if (stworyBoardEntry.username === "" || !stworyBoardEntry.username) {
        const answer = await supabase
        .from(UserTable)
        .upsert({
          username: stworyBoardEntry.username,
          isInstagramUser: false,
        }).select("*")

        data = answer.data;
        error = answer.error;
        console.log('created new user', answer);
      } else {
        const answer = await supabase
        .from(UserTable)
        .upsert({
          username: stworyBoardEntry.username,
          isInstagramUser: false,
          id: stworyBoardEntry.user_id
        }).select("*")

        console.log("updating user with new username", answer)

        data = answer.data;
        error = answer.error;
        stworyBoardEntry.user_id = data![0].id;

        return supabase
          .from(StworyBoardTable)
          .upsert({
            id: stworyBoardEntry.id,
            stwory: JSON.stringify(stworyBoardEntry.stwory),
            stwory_id: stworyBoardEntry.stwory_id,
            room_id: stworyBoardEntry.room_id ?? "",
            language: stworyBoardEntry.language,
            user_id: stworyBoardEntry.user_id,
            likes: 0,
            challenge: stworyBoardEntry.challenge,
          })
          .select();
        
      }
      stworyBoardEntry.user_id = data![0].id;

      if (error) {
        console.log("could not insert new user", error)
      }
      
      // @ts-ignore
      // new user inserted
    }


  return supabase
    .from(StworyBoardTable)
    .upsert({
      stwory: JSON.stringify(stworyBoardEntry.stwory),
      stwory_id: stworyBoardEntry.stwory_id,
      language: stworyBoardEntry.language,
      user_id: stworyBoardEntry.user_id,
      room_id: stworyBoardEntry.room_id ?? "",
      likes: 0,
      challenge: stworyBoardEntry.challenge,
    })
    .select();
}

export enum LeaderboardType {
  // Friends = "friends",
  World = "world",
  RealTime = "realtime",
  Me = "me"
}



export const RoomTable = "room"

export const getRoom = async (code: string) => {
  const {data: roomData, error } = await supabase.from(RoomTable).select('*').eq('code', code);

  if (error) {
    return error
  }
  `
  *,
  username:username!(
    id,
    username
  )(
    user_id = stwory.user_id
  )
`
  const {data: stwories, error: error2 } = await supabase.from(StworyBoardTable).select(
    `
    *
    
  `
  ).eq('room_id', code);
  
  if (error2) {
    return error2
  }

  return {
    roomData: roomData![0],
    stwories: stwories!,
  }

}
export const createRoom = async (prompt: string) => {
  const {data, error} = await supabase.from(RoomTable).insert([{prompt}]).select('*')



  console.log(data, error);
  if (error) {

    return 
  }


  const room = data![0];


  return await supabase.from(RoomTable).upsert({
    // @ts-ignore
    id: room.id,
    // @ts-ignore
    code: room.id.slice(0,6)
  }).select('*');

} 

export const getMyEntry = async (id: string, logged_id: number = 0) => {
      return await supabase.rpc('querymyentry', {logged_id, myid: id})
}
export const getALlStworyBoardEntries = async (language: string, leaderboardType: LeaderboardType, logged_id: number = 0, id: string, queried_date: string) => {
  if (!language || !leaderboardType || language === "") {return {data: [], error: null}}

  if (language !== "en" && language !== "es") {return {data: [], error: null}}
  if (queried_date === "" || !queried_date) {return {data: [], error: null}}
  
  switch (leaderboardType) {
    case LeaderboardType.RealTime: {
      return await supabase.rpc('queryrealtimeleaderboard', {logged_id, queried_date})
      .eq('language', language);
      
    }
  case LeaderboardType.World: {
    return await supabase.rpc('querybestleaderboard', {logged_id, queried_date})
      .eq('language', language);

  }
  case LeaderboardType.Me: {
    if (!id) { return ""}
    return await supabase.rpc('querymyentry', {logged_id, myid: id, queried_date})
  }

}
}

export const LikeTable = "likes"

interface Likes {
  stwory_board_id: string;
  user_id: number;
}

export const likePost = async (likes: Likes) => {
return await supabase
.from(LikeTable)
.insert(
  likes
)
}

export const unlikePost = async (likes: Likes) => {
return await supabase
.from(LikeTable)
.delete()
.eq(
  "stwory_board_id", likes.stwory_board_id)
  .eq("user_id", likes.user_id)
}

export interface User {
  id: number;
  username: string;
  isInstagram: boolean;
  created_at: string;
  imageUrl: string;
}

// we need to get user profile image link as well
export const UserTable = "person"

export const upsertUser = async (user: any, isInstagramUser: boolean) => {
  return await supabase
  .from(UserTable)
  .upsert([
    {id: user.id, username: user.username, isInstagramUser: isInstagramUser },
  ])
  .select()
}

export const purgeData = async (userId: number) => {
    return await supabase.rpc('deletedata', {my_id: userId})
  
}

export const purgeUser = async (userId: number) => {
    return await supabase.rpc('deleteuser', {my_id: userId})
  
}