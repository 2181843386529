import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select';
import { FC, useEffect } from 'react';

interface SelectWrapperProps {
  value: string;
  allDates: string[];
  onChange: any;
}

export const SelectWrapper: FC<SelectWrapperProps> = ({
  value,
  onChange,
  allDates,
}) => {
  useEffect(() => {
    const setup = async () => {};

    setup();
  }, []);

  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger className="w-[140px] text-right italic">
        {value}
      </SelectTrigger>
      <SelectContent>
        {allDates?.map((date: string, key: number) => (
          <SelectItem key={key} value={date}>
            {date}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

