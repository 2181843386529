import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { FC, useEffect, useRef, useState } from 'react';
import { Stage, Layer, Line } from 'react-konva';

interface Props {
  lines: any;
  setLines?: any;
  display?: boolean;
}
// @ts-ignore
const Drawing: FC<Props> = ({ lines, setLines, display }) => {
  if (lines === null) {
    return;
  }

  const [tool, setTool] = useState('pen');
  const isDrawing = useRef(false);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  const divRef = useRef(null);

  // find the parent container's w and h and then manually set those !
  useEffect(() => {
    // @ts-ignore
    if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
      setDimensions({
        // @ts-ignore
        width: divRef.current.offsetWidth,
        // @ts-ignore
        height: divRef.current.offsetHeight,
      });
    }
  }, []);

  // @ts-ignore
  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    // @ts-ignore
    setLines([...lines, { tool, points: [pos.x, pos.y] }]);
  };

  // @ts-ignore
  const handleMouseMove = (e) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    // @ts-ignore
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  const getStage = () => {
    if (!display) {
      return (
        <Stage
          width={dimensions.width}
          height={350}
          // @ts-ignore
          onPointerDown={display || handleMouseDown}
          onPointerMove={display || handleMouseMove}
          onPointerUp={display || handleMouseUp}
        >
          <Layer>
            {
              // @ts-ignore
              lines.map((line, i) => (
                <Line
                  key={i}
                  // @ts-ignore
                  points={line.points}
                  stroke="#df4b26"
                  strokeWidth={5}
                  tension={0.5}
                  lineCap="round"
                  lineJoin="round"
                  globalCompositeOperation={
                    // @ts-ignore
                    line.tool === 'eraser' ? 'destination-out' : 'source-over'
                  }
                />
              ))
            }
          </Layer>
        </Stage>
      );
    }

    return (
      <Stage className="" width={dimensions.width} height={dimensions.height}>
        <Layer>
          {
            // @ts-ignore
            lines.map((line, i) => (
              <Line
                key={i}
                // @ts-ignore
                points={line.points}
                stroke="#df4b26"
                strokeWidth={5}
                tension={0.5}
                lineCap="round"
                lineJoin="round"
                globalCompositeOperation={
                  // @ts-ignore
                  line.tool === 'eraser' ? 'destination-out' : 'source-over'
                }
              />
            ))
          }
        </Layer>
      </Stage>
    );
  };

  const displayClasses = display
    ? 'absolute w-[100%] h-[95%] left-[0%] top-[0%] translate-x-[0%] translate-y-[5%]'
    : '';

  return (
    <div
      ref={divRef}
      className={`${displayClasses} overflow-x-scroll overflow-y-hidden`}
    >
      {display ? (
        <></>
      ) : (
        <div className={`flex justify-center overflow-y-hidden`}>
          <Select value={tool} onValueChange={(value) => setTool(value)}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select a drawing tool" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="pen">Pen</SelectItem>
              <SelectItem value="eraser">Eraser</SelectItem>
            </SelectContent>
          </Select>
        </div>
      )}
      <div className={`${display ? '' : 'border'}`}>{getStage()}</div>
    </div>
  );
};

export default Drawing;

