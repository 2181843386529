import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';
import { userFlow } from '@/lib/hooks/useUser';
import { Instagram } from 'lucide-react';

export const LoginViaInstagram = () => {
  return (
    <Button
      className="text-foreground"
      onClick={() => {
        toast({
          title: 'Redirecting you to Instagram...',
          // description: '',
        });

        userFlow.initiateOAuthProcess();
      }}
      variant={'outline'}
    >
      Login via{' '}
      <span className="pl-2">
        <Instagram />
      </span>
    </Button>
  );
};

