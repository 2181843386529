import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog';
import { Textarea } from '@/components/ui/textarea';
import { createRoom } from '@/lib/db/supabase';
import { t } from 'i18next';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface CreateRoomProps {
  open: boolean;
  setOpen: any;
}
export const CreateRoom: FC<CreateRoomProps> = ({ open, setOpen }) => {
  const [prompt, setPrompt] = useState<string>('');
  const navigate = useNavigate();
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="w-[98%] mx-auto block">
        <DialogTitle className="text-center h3 p-0 m-0">
          {t('createRoom')}
        </DialogTitle>
        <DialogDescription>
          <h3 className="p text-center mb-4">{t('createRoomDesc')}</h3>
          <Textarea
            onChange={(e) => setPrompt(e.target.value)}
            value={prompt}
            className="text-base mb-4"
            placeholder={t('welcome.desc')}
          />

          <Button
            className="w-full"
            onClick={async () => {
              // create room.
              // navigate to room
              //@ts-ignore
              const { data, error } = await createRoom(prompt);

              if (error) {
                return;
              }

              // @ts-ignore
              const room = data![0];

              navigate('/room?' + room.code);
            }}
          >
            {t('createRoom')}
          </Button>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};

export default CreateRoom;

