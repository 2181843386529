import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { t } from 'i18next';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface JoinRoomProps {
  open: boolean;
  setOpen: any;
}
export const JoinRoom: FC<JoinRoomProps> = ({ open, setOpen }) => {
  const [code, setCode] = useState<string>('');
  const navigate = useNavigate();
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="w-[98%] mx-auto block">
        <DialogTitle className="text-center h3 p-0 m-0">
          {t('joinRoom')}
        </DialogTitle>
        <DialogDescription>
          <h3 className="p text-center mb-4">{t('joinRoomDesc')}</h3>
          <Input
            onChange={(e) => setCode(e.target.value)}
            value={code}
            className="text-base mb-4"
            placeholder={t('Code')}
          />

          <Button
            className="w-full"
            onClick={async () => {
              navigate('/room?' + code);
            }}
          >
            {t('joinRoom')}
          </Button>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};

export default JoinRoom;

