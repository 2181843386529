import i18next from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useLanguage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem('lang');

    if (lang) {
      i18next.changeLanguage(lang);
    } else {
      localStorage.setItem('lang', 'en');
      i18next.changeLanguage('en');
    }
  }, []);

  return { t };
};

