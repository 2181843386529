import { useEffect, useState } from 'react';
import { appId } from '../db/auth';
import {
  User,
  insertStworyBoardEntry,
  purgeData,
  purgeUser,
  upsertUser,
} from '../db/supabase';
import { StoryContents } from '../utils';

interface UserInternals {
  userStorage: string;
  token?: string;
  tokenStorage: string;
  userId?: string;
  getUser(): User | null;
  getInstagramAuthUrl(): string;
  initiateOAuthProcess(): void;
  getAuthCode(): string;
  exchangeCodeForToken(code: string): Promise<OAuthResponse>;
  queryUserNode(oAuthResponse: OAuthResponse): Promise<UserNode>;
  executeAuth(): any;
  oAuthProcess(): Promise<OAuthResponse>;
  commenceWindow: boolean;
  userCreation: UserCreation;
  logout(): void;
  purgeData(): void;
  purgeUser(): void;
}

export enum UserCreation {
  Default = 'default',
  FromGame = 'from-game',
}

interface OAuthResponse {
  access_token: string;
  user_id: string;
  permissionhs: string[];
}

interface UserNode {
  id: number;
  username: string;
}
export const userFlow: UserInternals = {
  userStorage: '@user',
  tokenStorage: '@token',
  commenceWindow: false,
  userCreation: UserCreation.Default,

  getUser(): User | null {
    const user = localStorage.getItem(userFlow.userStorage) || '';

    if (user) {
      return JSON.parse(user);
    }

    return null;
  },

  getInstagramAuthUrl(): string {
    const url =
      `https://api.instagram.com/oauth/authorize` +
      `?client_id=${appId}` +
      `&redirect_uri=https://stwory.io/` +
      `&scope=user_profile,user_media` +
      `&response_type=code`;

    return url;
  },

  initiateOAuthProcess(userCreation: UserCreation = UserCreation.Default) {
    window.location.href = this.getInstagramAuthUrl();
    this.userCreation = userCreation;
    this.commenceWindow = true;
  },

  logout(): void {
    localStorage.removeItem(userFlow.userStorage);
    localStorage.removeItem(userFlow.tokenStorage);
    window.location.href = '/';
  },

  purgeData(): void {
    const user = userFlow.getUser();
    purgeData(user?.id!);
    window.location.href = '/?purged';
    console.log('data purged');
  },

  purgeUser() {
    const user = userFlow.getUser();
    purgeUser(user?.id!);
    userFlow.logout();
    window.location.href = '/?user-deleted';
  },

  getAuthCode(): string {
    const authCode = window.location.search.split('?code=');

    if (authCode.length <= 1) return '';

    return authCode[1].split('#')[0];
  },

  async exchangeCodeForToken(code: string): Promise<OAuthResponse> {
    const body = {
      code: code,
      redirect_uri: 'https://stwory.io/',
    };

    const response = await fetch(
      `https://stwory-shy-morning-5919.fly.dev/endpoint`,
      {
        // mode: 'no-cors',
        method: 'POST',
        body: JSON.stringify(body),
      }
    );

    const oAuthResponse: string = await response.json();

    return JSON.parse(oAuthResponse);
  },

  async queryUserNode(oAuthResponse: OAuthResponse): Promise<UserNode> {
    if (!oAuthResponse?.user_id) return {} as Promise<UserNode>;

    const response = await fetch(
      `https://graph.instagram.com/${oAuthResponse.user_id}?fields=id,username&access_token=${oAuthResponse.access_token}`,
      {
        method: 'GET',
      }
    );

    const userNode: UserNode = await response.json();

    return userNode;
  },

  async oAuthProcess(): Promise<OAuthResponse> {
    const authCode = this.getAuthCode();

    if (authCode === '') return {} as Promise<OAuthResponse>;

    const oAuthResponse: OAuthResponse =
      await this.exchangeCodeForToken(authCode);

    if (!oAuthResponse?.access_token) return {} as Promise<OAuthResponse>;

    localStorage.setItem(this.tokenStorage, oAuthResponse.access_token);

    return oAuthResponse;
  },

  async executeAuth() {
    let user: any = localStorage.getItem(this.userStorage) || '';

    if (user) return JSON.parse(user) as User;

    let oAuthResponse: OAuthResponse = await this.oAuthProcess();

    if (!oAuthResponse.access_token) {
      return {} as Promise<User>;
    }
    console.log('got access token: ', oAuthResponse);

    const userNode: UserNode = await this.queryUserNode(oAuthResponse);
    console.log('got user node:', userNode);

    const { data, error } = await upsertUser(userNode, true);
    console.log('upsert user: ', data, error);

    if (error) {
      return {} as Promise<User>;
    }

    user = data![0] as User;
    localStorage.setItem(this.userStorage, JSON.stringify(user));

    const storyContents = localStorage.getItem(StoryContents);
    if (this.userCreation === UserCreation.FromGame || storyContents) {
      const stworyBoardEntry = JSON.parse(storyContents!);
      stworyBoardEntry.user_id = user.id;
      // @ts-ignore
      const { data } = await insertStworyBoardEntry(stworyBoardEntry);

      console.log(data);

      // @ts-ignore
      window.location.href = '/leaderboard?' + data[0]!.id;
    }

    return user;
  },
};

export const useUser = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const commence = async () => {
      setUser(await userFlow.executeAuth());

      setLoading(false);
    };

    commence();
  }, []);

  return { user, isLoading };
  // const [user, _] = useState<User | null>(User.getUser() || null);
};

