import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import Game from './pages/Game/Game';
import Leaderboard from './pages/Leaderboard/Leaderboard';
import { Toaster } from './components/ui/toaster';
import { ThemeProvider } from './components/ui/ThemeProvider';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Terms from './pages/Terms/Terms';

import { PostHogProvider } from 'posthog-js/react';
import Room from './pages/Room';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const options = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
};

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <QueryClientProvider client={client}>
    <PostHogProvider
      apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <ThemeProvider defaultTheme="light">
        <React.StrictMode>
          <Toaster />
          <Router>
            <Routes>
              <Route path="/" element={<Game />} />
            </Routes>
            <Routes>
              <Route path="/main" element={<App />} />
            </Routes>
            <Routes>
              <Route path="/leaderboard" element={<Leaderboard />} />
            </Routes>
            <Routes>
              <Route path="/room" element={<Room />} />
            </Routes>
            <Routes>
              <Route path="/privacy" element={<PrivacyPolicy />} />
            </Routes>
            <Routes>
              <Route path="/terms" element={<Terms />} />
            </Routes>
          </Router>
        </React.StrictMode>
      </ThemeProvider>
    </PostHogProvider>
  </QueryClientProvider>
);

