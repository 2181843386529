import Nav from '@/components/shared/TopNav/TopNav';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/use-toast';
import {
  ChallengeType,
  StworyBoardEntry,
  getRoom,
  insertStworyBoardEntry,
} from '@/lib/db/supabase';
import i18next, { t } from 'i18next';
import { useEffect, useState } from 'react';
import { BeatLoader } from 'react-spinners';

interface Room {
  roomData: any;
  stwories: StworyBoardEntry[];
}

export const Room = () => {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<Room>();
  const [stworyContents, setStworyContents] = useState<string>();
  // const [username, setUsername] = useState<string>();
  // const { user } = useUser();

  const setup = async (roomCode: string) => {
    const roomData = await getRoom(roomCode);

    // @ts-ignore
    if (!roomData?.roomData) {
      setError(true);
    }
    // @ts-ignore
    setData(roomData);

    setLoading(false);
  };

  useEffect(() => {
    const str = window.location.search.split('?');
    const roomCode = str[1];

    setup(roomCode);
  }, []);

  console.log(data);

  const template = () => {
    let content;
    if (isLoading) {
      content = (
        <main className="mx-auto px-4 h-[100%] pt-24 lg:max-w-[900px]">
          <BeatLoader />
        </main>
      );
    } else if (error) {
      content = (
        <main className="mx-auto px-4 h-[100%] pt-24 lg:max-w-[900px]">
          😥😥😥😥😥 There's no room here
        </main>
      );
    } else {
      content = (
        <main className="mx-auto px-4 h-[100%] pt-24 lg:max-w-[900px]">
          <h2 className="h2 text-center text-primary relative">
            {data?.roomData.prompt}
            <span className="absolute right-4 text-foreground top-[50%]">
              Room Code:&nbsp;{data?.roomData.code}
            </span>
          </h2>
          <Tabs defaultValue="Create">
            <TabsList className="py-0 flex h-[50px]">
              <TabsTrigger
                className="h-[100%] translate-y-[4px] shadow-none px-10 py-4"
                value={'Create'}
              >
                {t('create')}
              </TabsTrigger>
              <TabsTrigger
                className="h-[100%] translate-y-[4px] shadow-none px-10 py-4"
                value={'Entries'}
              >
                {t('entries')}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="Create">
              <form>
                <Textarea
                  className="h-[300px] block text-foreground text-md mb-4"
                  placeholder={t('storyPlaceholder')}
                  value={stworyContents}
                  onChange={(e) => setStworyContents(e.target.value)}
                />
                <Button
                  className="w-full"
                  onClick={async (e) => {
                    e.preventDefault();

                    const entry = {
                      stwory: stworyContents,
                      language: i18next.language,
                      challenge: ChallengeType.Story,
                      room_id: data?.roomData.code,
                    };

                    await insertStworyBoardEntry(entry);

                    setup(data?.roomData.code);

                    toast({
                      description: 'Successfully created your entry',
                    });
                  }}
                >
                  {t('submit')}
                </Button>
              </form>
            </TabsContent>
            <TabsContent value="Entries">
              <div className="grid grid-cols-2 gap-2">
                {data?.stwories.length === 0 && (
                  <p className="text-center text-foreground/70">
                    It might take awhile to show your entires...
                  </p>
                )}
                {data?.stwories.map((stwory: StworyBoardEntry) => {
                  return (
                    <div
                      key={stwory.id}
                      className="relative bg-secondary border h-[200px] flex justify-center items-center overflow-y-scroll"
                    >
                      <p className="absolute">{stwory.username}</p>

                      <p className="text-center">{JSON.parse(stwory.stwory)}</p>
                    </div>
                  );
                })}
              </div>
            </TabsContent>
          </Tabs>
        </main>
      );
    }

    return (
      <>
        <Nav backArrow="/main" />
        {content}
      </>
    );
  };

  return template();
};

export default Room;

