export const appId = import.meta.env.VITE_INSTAGRAM_APP_ID
export const appSecret = import.meta.env.VITE_INSTAGRAM_APP_SECRET

export const getInstagramAuthUrl = () => {

	const url = `https://api.instagram.com/oauth/authorize`
		+ `?client_id=${appId}`
		+ `&redirect_uri=https://stwory.fun/`
		+ `&scope=user_profile,user_media`
		+ `&response_type=code`;



		return url
}