import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// LANG
i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          welcome: {
            title: "Welcome to",
            desc: "Your creation awaits."
          },
          faq: {
            question1: "How long can the story be?",
            answer1: "However long you want baby",
            question2: "?",
            answer2: "",
          },
          steps: {
            words: "words",
            story: "story",
            done: "done",
          },
          play: "Play now!",
          now: "now",
          createRoom: "Create Room",
          createRoomDesc: "Choose your prompt for the room to write about. It doesn't have to be two words :)",
          joinRoom: "Join Room",
          joinRoomDesc: "Enter the code to join a room!",
          noNeedToSignUp: "No need to sign up!",
          leaderboard: "Leaderboard",
          Logout: "Logout",
          purgeData: "Purge Data",
          howToPlay: "How to play",
          directions: "Given two words, create a story of any length!",
          deleteAccount: "Delete Account",
          deleteAccountDesc: "Are you sure you want to delete your account? This will delete everything, including all related data.",
          explanations: {
            game: "Every day there will be new set of two words",
            todo: "Your goal is to create a stwory based on the two words.",
            winner:"The stwory with the most amount of votes for the day wins!",
            thegame: "The Game",
            thetodo: "To Play",
            thewinner:"The Winner",
            words: "Daily Words",
            example: "Example",
            story: `David's friends huddled around him to wish him happy bday. They got him
            a cheesecake, lit up the candles and sang happy bday. But the party only
            became lit after they smashed his head into the cheesecake.`,
            stwory: `Lit Cheesecake`
          },
          language: "Language",
          firstWord: "First Word",
          secondWord: "Second Word",
          challenge: "Challenge",
          storyPlaceholder: "Your creation awaits...",
          noDataLeaderboard: "No data, be the first to post!",
          noResults: "No results, be the first!",
          username: "username",
          me: "me",
          leaderboardType: {
            friends: "Friends 🤝",
            world: "Top 🥇",
            realtime: "Realtime 🌎",
            me: "me",
          },
          addAUsername: "Congratulations! You made your first post.",
          addUserName: "Add username",
          wouldYouLikeToSubmit: "Would you like to add a username or submit via Instagram?",
          submit: "submit",
          mustBeLoggedIn: "You must be logged in to like!",
          days: {
            today: "Today",
            yesterday: "Yesterday",
          },
          submitWithInstagramAccount: "Submit with your Instagram account",
          purgeDataDesc: `Are you sure you want to purge your data? This means that all of
          your likes & your stworys will be deleted
        `,
        },
      },
      es: {
        translation: {
          welcome: {
            title: "Bienvenidos a",
            desc: "Tu creación espera.",
          },
          faq: {
            question1: "¿Cuanto puede durar la historia?",
            answer1: "Puedes elegir la longitud que quieras",
            question2: "?",
            answer2: "",
          },
          play: "Juega ahora!",
          leaderboard: "Clasificación",
          steps: {
            words: "palabras",
            story: "historia",
            done: "hecho!",
          },
          noNeedToSignUp: "No hay necesidad de registrarse!",
          Logout: "Cerrar sesión",
          createRoom: "Crea sala",
          joinRoom: "Unirse a sala",
          howToPlay: "Como se juega",
          purgeData: "Purgar datos",
          deleteAccount: "Borra cuenta",
          deleteAccountDesc: "Segura? Borrará todo.",
          purgeDataDesc: `Estás segura? Significa que todas sus me gustas y sus stworys serán eliminados.`,
          language: "lengua",
          firstWord: "Primera Palabra",
          secondWord: "Segunda Palabra",
          challenge: "Desafío",
          storyPlaceholder: "Tu creación te espera",
          noDataLeaderboard: "No hay datos en la clasificación. Sea el pimero publicar!",
          noResults: "No hay resultados, sea el primero!",
          username: "usuario",
          me: "yo",
          explanations: {
            game: "Cada día habra un nuevo set de dos palabras",
            todo: "Tu meta es crear un stwory basado en las dos palabras.",
            winner:"La historia con las The stwory with the most amount of votes for the day wins!",
            thegame: "El juego",
            thetodo: "Jugar",
            thewinner:"El ganador",
            words: "Palabras Diarias",
            example: "Ejemplo",
            stwory: "Loco día",
            story: "Vi un elefante en la calle hoy. Vivo en Canada. Hoy fue un día loco.",
          },
          leaderboardType: {
            friends: "Amigos 🤝",
            me: "yo",
            world: "Top 🥇",
            realtime: "Ahora 🌎",
          },
          now: "ahora",
          addAUsername: "Felicidades, hiciste tu primera publicación.",
          addUserName: "Añadir usuario",
          wouldYouLikeToSubmit: "Would you like to add a username or submit via Instagram?",
          submit: "enviar",
          mustBeLoggedIn: "¡Debes estar conectado para dar like!",
          days: {
            today: "Hoy",
            yesterday: "ayer",
          },
          submitWithInstagramAccount: "Envia con tu cuenta de Instagram",
        },
      },
    },
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export const StoryContents = "story-contents"