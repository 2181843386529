import './App.css';
import HelloFromFounder from './components/shared/HelloFromFounder/HelloFromFounder';
import MadeWithLove from './components/shared/MadeWithLove/MadeWithLove';
import { Button } from './components/ui/button';
import MeImage from '/me.webp';
import Nav from './components/shared/TopNav/TopNav';
import StworyIcon from '../public/ios/180.png';
import { useLanguage } from './lib/hooks/language';
import { useEffect, useState } from 'react';
import { toast } from './components/ui/use-toast';
import HowToPopover from './components/shared/HowToPopover/HowtToPopover';
import CreateRoom from './components/shared/CreateRoom/CreateRoom';
import JoinRoom from './components/shared/JoinRoom/JoinRoom';
// example of a custom hook
export default function App() {
  const { t } = useLanguage();
  const [openPopover, setOpenPopover] = useState(false);
  const [openCreateRoom, setOpenCreateRoom] = useState(false);
  const [openJoinRoom, setOpenJoinRoom] = useState(false);

  useEffect(() => {
    const purged = window.location.search;
    if (purged.includes('purged')) {
      toast({
        title: 'Data has been purged',
        description:
          'It might take a little bit to show up in the leaderboard. If it did not work, then try again :).',
      });
    }
    if (purged.includes('user-deleted')) {
      toast({
        title: 'user has been deleted!',
        description: "We'll miss you :(",
      });
    }
  }, []);

  return (
    <main className="flex flex-col justify-between mx-auto  px-4 sm:px-6 lg:px-8 pt-12 gap-12 min-h-screen">
      <Nav />
      <div>
        <div className="flex justify-center">
          <img src={StworyIcon} alt="" className="w-[180px] rounded-full" />
        </div>
        <h1 className="text-center text-primary h1">Stwory</h1>
        <p></p>
      </div>
      <div className="flex gap-4 lg:gap-6 justify-center flex-col align-center h-[100%] lg:max-w-[800px] lg:w-full lg:mx-auto">
        <a className="contents" href="/">
          <Button className="relative">
            <span className="">{t('play')}</span>
            <span className="absolute -top-5 text-foreground/60 text-base italic left-8 fancy">
              {t('noNeedToSignUp')}
            </span>
          </Button>
        </a>
        <a className="contents" href="/leaderboard">
          <Button variant={'secondary'}>{t('leaderboard')}</Button>
        </a>
        <Button
          className="relative"
          variant={'outline'}
          onClick={() => setOpenCreateRoom(true)}
        >
          {t('createRoom')}
        </Button>
        <Button
          className="relative"
          variant={'outline'}
          onClick={() => setOpenJoinRoom(true)}
        >
          {t('joinRoom')}
        </Button>
        <Button
          className="w-full"
          variant={'ghost'}
          onClick={() => setOpenPopover(true)}
        >
          {t('howToPlay')}
        </Button>
        <HowToPopover
          openDialogue={openPopover}
          setOpenDialogue={setOpenPopover}
        />
        <CreateRoom open={openCreateRoom} setOpen={setOpenCreateRoom} />
        <JoinRoom open={openJoinRoom} setOpen={setOpenJoinRoom} />

        {/* <HowToPopover /> */}
      </div>
      <footer className="lg:mx-auto lg:max-w-[800px] mt-24">
        <HelloFromFounder
          imageAlt={'David Serrano'}
          imageURL={MeImage}
          founderDescription={`Hey y'all 👋, I'm David, a soloprenuer who wants to make fun games for all! If you have any ideas, questions, notice any bugs, don't be afraid to reach me via discord!`}
        />
        <div className="mt-2">
          <MadeWithLove
            githubHandle="serranoio"
            instagramHandle="davidserrano.io"
            discordHandle="https://discord.gg/AtZusK29"
          />
        </div>
      </footer>
    </main>
  );
}

