import { FC } from 'react';
import ProfileCard from '../ProfileCard/ProfileCard';

interface NavProps {
  backArrow?: string;
  title?: string;
}

const Nav: FC<NavProps> = ({ backArrow }) => {
  return (
    <>
      <div className="absolute top-4 left-4 flex items-center gap-8">
        {backArrow && (
          <a
            href={backArrow}
            className="h3 text-foreground border rounded-full p-4"
          >
            &larr;
          </a>
        )}
      </div>
      <ProfileCard />
    </>
  );
};

export default Nav;

