import Nav from '@/components/shared/TopNav/TopNav';
import { Button } from '@/components/ui/button';
import {
  DialogHeader,
  DialogFooter,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import {
  ChallengeType,
  StworyDaily,
  getStworyDailyTable,
  insertStworyBoardEntry,
} from '@/lib/db/supabase';
import { useUser } from '@/lib/hooks/useUser';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Drawing from './Drawing';
import i18next from 'i18next';
import { useLanguage } from '@/lib/hooks/language';
import HowToPopover from '@/components/shared/HowToPopover/HowtToPopover';

export default function Game() {
  const navigate = useNavigate();
  const { t } = useLanguage();
  const { user } = useUser();

  const [isError, setError] = useState<boolean>(false);
  const [stworyDailyRow, setStworyDailyRow] = useState<StworyDaily>(
    {} as StworyDaily
  );

  const [openHowToPlayDialogue, setOpenHowToPlayDialogue] =
    useState<boolean>(true);

  const [challenge, setChallenge] = useState<ChallengeType | null>(null);
  const [storyContents, setStoryContents] = useState<string>('');
  const [showDialogue, setShowDialogue] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [lines, setLines] = useState<any>([]);

  const [submittedId, setSubmittedId] = useState<string>('');

  useEffect(() => {
    const story = localStorage.getItem('storyContents');

    if (story) {
      setStoryContents(story);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('storyContents', storyContents);
  }, [storyContents]);

  useEffect(() => {
    const setupGame = async () => {
      const { data, error } = await getStworyDailyTable(i18next.language);

      if (error) {
        setError(true);
      }

      const stworyDaily: StworyDaily = data![0];

      setStworyDailyRow(stworyDaily);
      setChallenge(stworyDaily.challenge);
    };

    if (i18next.language) {
      setupGame();
    }
  }, [i18next.language]);

  const makeStworyContents = () => {
    let stwory;
    if (challenge === ChallengeType.Story) {
      stwory = storyContents;
    } else {
      stwory = lines;
    }

    return stwory;
  };

  const noUserSubmitDialoue = (
    <Dialog open={showDialogue} onOpenChange={setShowDialogue}>
      <DialogContent className="w-[100%] sm:max-w-[425px]">
        <DialogHeader className="mt-4">
          <DialogTitle className="text-center text-foreground mt-2">
            {t('addAUsername')}
          </DialogTitle>
          <p className="small italic text-foreground/60 text-center">
            {t('wouldYouLikeToSubmit')}
          </p>
        </DialogHeader>
        <Input
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          placeholder={t('username')}
          className="w-full text-foreground text-md"
        />
        <Button
          type="submit"
          className="w-full"
          onClick={() => {
            const stworyBoardEntry = {
              username: username,
              id: submittedId,
              // @ts-ignore
              user_id: submittedId.userId,
              stwory_id: stworyDailyRow.id,
              language: i18next.language,
              challenge: challenge!,
              stwory: makeStworyContents(),
            };

            console.log('updated entry', stworyBoardEntry);

            insertStworyBoardEntry(stworyBoardEntry, true);

            //@ts-ignore
            navigate('/leaderboard?' + submittedId.postId);
          }}
        >
          {t('addUserName')}
        </Button>
        <div className="flex gap-2 mb-4">
          {/* <Checkbox className="flex" id="terms" />
          <label
            htmlFor="terms"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            This is my instagram username
          </label> */}
        </div>
        <DialogFooter></DialogFooter>
        <div className="w-full flex justify-between items-center">
          {/* <div className="w-auto">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="border p-1 px-4 rounded-full">
                  ?
                </TooltipTrigger>
                <TooltipContent>
                  <img src={ToolTipGif} />
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div> */}
          {/* <a
            className="small text-center text-foreground underline cursor-pointer w-[85%]"
            onClick={() => {
              const stworyBoardEntry = {
                stwory_id: stworyDailyRow.id,
                stwory: makeStworyContents(),
                language: i18next.language,
                username: username,
                challenge: challenge!,
              };

              localStorage.setItem(
                StoryContents,
                JSON.stringify(stworyBoardEntry)
              );
              userFlow.initiateOAuthProcess();
            }}
          >
            {/* <Button className="text-center w-full" variant={'outline'}>
              {t('submitWithInstagramAccount')}
            </Button> 
          </a> */}
        </div>
      </DialogContent>
    </Dialog>
  );

  return (
    <main className="mx-auto px-4 h-[100%] pt-24 lg:max-w-[900px]">
      <Nav backArrow="/main" title={t('game')} />

      <HowToPopover
        openDialogue={openHowToPlayDialogue}
        setOpenDialogue={setOpenHowToPlayDialogue}
      />
      {isError || !stworyDailyRow?.firstWord ? (
        <p className="text-center text-primary">Please connect to internet!</p>
      ) : (
        <>
          <p className="p text-center small text-sm text-foreground/70">
            {t('firstWord')}
          </p>
          <h2 className="h2 text-center text-primary">
            {stworyDailyRow?.firstWord}
          </h2>
          <p className="p text-center small text-sm text-foreground/70">
            {t('secondWord')}
          </p>
          <h2 className="h2 text-center text-primary border-bottom">
            {stworyDailyRow?.secondWord}
          </h2>
          <p className="p text-center text-primary  large flex gap-4 justify-center">
            {/* <span className="p text-primary/80">{t('challenge')}</span>
            <span className="font-bold">{stworyDailyRow.challenge}</span> */}
          </p>
          {/* <p className="border-bottom border-2 my-6"></p> */}
          <form>
            {challenge === ChallengeType.Drawing ? (
              <p className="p text-center text-sm text-gray-500">
                <Drawing
                  // @ts-ignore
                  setLines={setLines}
                  lines={lines}
                />
              </p>
            ) : (
              <Textarea
                className="h-[300px] block text-foreground text-md"
                placeholder={t('storyPlaceholder')}
                value={storyContents}
                onChange={(e) => setStoryContents(e.target.value)}
              />
            )}
            <Button
              className="w-full mt-4"
              type="submit"
              onClick={async (e: any) => {
                e.preventDefault();
                const entry = {
                  stwory_id: stworyDailyRow.id,
                  user_id: user?.id ? user.id : 0,
                  stwory: makeStworyContents(),
                  language: i18next.language,
                  challenge: challenge!,
                };

                // @ts-ignore
                const { data, error } = await insertStworyBoardEntry(
                  entry,
                  user?.id ? false : true
                );

                console.log('entry:', data);

                setSubmittedId({
                  // @ts-ignore
                  postId: data![0].id,
                  userId: data![0].user_id,
                });

                if (!user?.id) {
                  setShowDialogue(true);
                } else {
                  navigate('/leaderboard?' + data![0].id);
                }
              }}
            >
              {t('submit')}
            </Button>
          </form>
        </>
      )}
      {noUserSubmitDialoue}
    </main>
  );
}

